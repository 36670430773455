import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../apiConfig'; // Import the base URL
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import '../assets/Login.css'; 
import email_icon from '../assets/img/email.png';
import user_icon from '../assets/img/person.png';
import password_icon from '../assets/img/password.png';
import phone_icon from '../assets/img/phone2.png';
import MyLogo from '../components/Logo.jsx';
import eye_icon from '../assets/img/eye.png';
import eye_slash_icon from '../assets/img/eye-slash.png';
import Swal from 'sweetalert2';
import { getAuthUser } from '../utils/auth';

const SignUp = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (getAuthUser()) {
        navigate('/dashboard'); // Redirect to dashboard if the user is logged in
    }
  }, [navigate]);
  
  // Form state
  const [formData, setFormData] = useState({
    fullname: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
    referralCode: '',
  });


  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate input on change
    if (errors[name]) {
      validateField(name, value);
    }
  };

  // Validate individual fields
  const validateField = (name, value) => {
    let fieldErrors = { ...errors };

    switch (name) {
      case 'fullname':
        fieldErrors.fullname = value ? '' : 'Full Name is required';
        break;
      case 'phone':
        fieldErrors.phone = value ? '' : 'Phone number is required';
        break;
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        fieldErrors.email = value
          ? emailRegex.test(value)
            ? ''
            : 'Invalid email address'
          : 'Email is required';
        break;
      case 'password':
        fieldErrors.password = value ? '' : 'Password is required';
        break;
      case 'confirmPassword':
        fieldErrors.confirmPassword = value ? '' : 'Confirm Password is required';
        if (value !== formData.password) {
          fieldErrors.confirmPassword = 'Passwords do not match';
        }
        break;
      default:
        break;
    }

    setErrors(fieldErrors);
  };

  // Validate entire form
  const validateForm = () => {
    let formErrors = {};

    if (!formData.fullname) formErrors.fullname = 'Full Name is required';
    if (!formData.phone) formErrors.phone = 'Phone number is required';
    if (!formData.email) {
      formErrors.email = 'Email is required';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        formErrors.email = 'Invalid email address';
      }
    }
    if (!formData.password) formErrors.password = 'Password is required';
    if (!formData.confirmPassword) formErrors.confirmPassword = 'Confirm Password is required';
    if (formData.password !== formData.confirmPassword)
      formErrors.confirmPassword = 'Passwords do not match';
    if (!agreedToTerms) formErrors.terms = 'You must agree to the terms and conditions';

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true); // Start loading
      try {
        const response = await fetch(`${API_BASE_URL}user/signup`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        const data = await response.json(); // Parse the response

        if (response.ok) {
          navigate(`/successpage?email=${formData.email}`); // Redirect to SuccessPage with email
        } else {

            let errorMessage;
            if (data.data && typeof data.data === 'object') {
                errorMessage = Object.keys(data.data)
                  .map((key) => data.data[key].join(' '))
                  .join(' ');
            } else if (typeof data.data === 'string') {
            errorMessage = data.data; // Use the string directly
            } else {
            errorMessage = 'Signup failed. Please try again.';
            }

            Swal.fire({
                icon: 'error',
                title: 'Sign Up Failed',
                text: errorMessage,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Try Again'
            });
        }
      } catch (error) {
        // Display SweetAlert for network or server errors
        Swal.fire({
            icon: 'error',
            title: 'An Error Occurred',
            text: 'Please try again later. ',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
        });
      } finally {
        setLoading(false); // Stop loading
      }
    }
  };

  return (
    <>
      <div className="container">
        <MyLogo title="Sign Up"/>
        <div className="inputs">
          <div className="input">
            <img src={user_icon} alt="User Icon" />
            <input
              type="text"
              name="fullname"
              placeholder="Full Name"
              value={formData.fullname}
              onChange={handleChange}
              onBlur={() => validateField('fullname', formData.fullname)}
            />
            {errors.fullname && <span className="error">{errors.fullname}</span>}
          </div>
          <div className="input">
            <img src={phone_icon} alt="Phone Icon" />
            <input
              type="phone"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleChange}
              onBlur={() => validateField('phone', formData.phone)}
            />
            {errors.phone && <span className="error">{errors.phone}</span>}
          </div>
          <div className="input">
            <img src={email_icon} alt="Email Icon" />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              onBlur={() => validateField('email', formData.email)}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="input">
            <img src={password_icon} alt="Password Icon" />
            <input
              type={passwordVisible ? 'text' : 'password'}
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              onBlur={() => validateField('password', formData.password)}
            />
            <img
              src={passwordVisible ? eye_slash_icon : eye_icon}
              alt="Toggle Password Visibility"
              onClick={() => setPasswordVisible(!passwordVisible)}
              className="toggle-password"
            />
            {errors.password && <span className="error">{errors.password}</span>}
          </div>
          <div className="input">
            <img src={password_icon} alt="Confirm Password Icon" />
            <input
              type={confirmPasswordVisible ? 'text' : 'password'}
              name="confirmPassword"
              placeholder="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleChange}
              onBlur={() => validateField('confirmPassword', formData.confirmPassword)}
            />
            <img
              src={confirmPasswordVisible ? eye_slash_icon : eye_icon}
              alt="Toggle Confirm Password Visibility"
              onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
              className="toggle-password"
            />
            {errors.confirmPassword && (
              <span className="error">{errors.confirmPassword}</span>
            )}
          </div>
          <div className="input">
            <img src={user_icon} alt="User Icon" />
            <input
              type="text"
              name="referralCode"
              placeholder="Referral Code (optional)"
              value={formData.referralCode}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="terms">
          <input
            type="checkbox"
            id="terms"
            checked={agreedToTerms}
            onChange={(e) => {
              setAgreedToTerms(e.target.checked);
              if (!e.target.checked) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  terms: 'You must agree to the terms and conditions',
                }));
              } else {
                setErrors((prevErrors) => {
                  const { terms, ...rest } = prevErrors;
                  return rest;
                });
              }
            }}
          />
          <label htmlFor="terms">
            I agree to the <a href="/terms">terms and conditions</a>
          </label>
          {errors.terms && <span className="error">{errors.terms}</span>}
        </div>

        <div className="submit-container">
            <div
                className={`submit ${!agreedToTerms || loading ? 'disabled' : ''}`}
                onClick={handleSubmit}
                >
                {loading ? (
                    <>
                    <div className="spinner"></div>
                    <span>Submitting...</span>
                    </>
                ) : (
                    'Sign Up'
                )}
            </div>
        </div>

        <div className="login-signup">
          Already have an account? <Link to="/login">Login</Link>
        </div>
      </div>
      <div className="footer">
        <p>powered by: AITS HUB&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;support line: 08037397040, 08136098764</p>
      </div>
    </>
  );
};

export default SignUp;
