import React, { useState } from 'react';
import '../assets/Dashboard.css';
import '../assets/Kyc.css';
import SideBar from '../components/SideBar.jsx';
import Footer from '../components/Footer.jsx';
import Header from '../components/Header.jsx';
import { getAuthUser } from '../utils/auth.js';


const BuyCableTv = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const user = getAuthUser();

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="dashboard-container">
            <SideBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

            <div className="main-content">
                <Header title="Cable Tv Subscription" />

                {user.data.user.kyc_status === 1 && (
                <div className="page-content">
                    <h1>Page Under Construction</h1>
                </div>
                )}
            </div>
            
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
};

export default BuyCableTv;
