import React, { useState, useEffect } from 'react';
import { FaChevronDown, FaChevronUp, FaCopy } from 'react-icons/fa';
import '../assets/Dashboard.css';
import '../assets/Table.css';
import { API_BASE_URL } from '../apiConfig'; // Import the base URL
import SideBar from '../components/SideBar.jsx';
import Footer from '../components/Footer.jsx';
import Header from '../components/Header.jsx';
import { getAuthUser } from '../utils/auth';
import useInactivityLogout from '../hooks/useInactivityLogout.js';

const Dashboard = () => {
    useInactivityLogout(2880); // Call the hook with a 30-minute or 2880 minutes (2 days) timeout
    const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Added state for sidebar
    
    const [areAccountsVisible, setAreAccountsVisible] = useState(false);
    const [accounts, setAccounts] = useState([]);

    const user = getAuthUser();
    //console.log(user.data);

    // Fetch accounts from API
    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}wallet/my-account-nos`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.data.token}`, // Use the correct token
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setAccounts(data.data || []); // Assuming the response contains an 'accounts' array
                    //console.log(data.data);
                } else {
                    console.error('Failed to fetch accounts');
                }
            } catch (error) {
                console.error('Error fetching accounts:', error);
            }
        };

        fetchAccounts();
    }, [user.data.token]); // Fetch accounts only after the component mounts

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    

    const toggleAccountsVisibility = () => {
        setAreAccountsVisible(!areAccountsVisible);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Copied to clipboard!');
        }).catch(err => {
            alert('Failed to copy: ' + err);
        });
    };

    return (
        <div className="dashboard-container">
            <SideBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /> {/* Pass state and function to Sidebar */}

            <div className="main-content">
                <Header title="Dashboard" />
                {user.data.user.kyc_status === 1 && (
                <div className="account-section">
                    <button className="toggle-accounts-button" onClick={toggleAccountsVisibility}>
                        My Bank Accounts &nbsp;&nbsp;&nbsp;&nbsp;{areAccountsVisible ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                    {areAccountsVisible && (
                        <div className="account-cards">
                            {accounts.length > 0 ? (
                                accounts.map((account, index) => (
                                    <div className="account-card" key={index}>
                                        <p className="account-bank">{account.bank_name}</p>
                                        <p className="account-number">
                                            {account.account_no}
                                            &nbsp;<FaCopy size={20} className="copy-icon" onClick={() => copyToClipboard(account.account_no)} />
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <p>No accounts available</p>
                            )} 
                        </div>
                    )}
                </div>
                )}
                {user.data.user.kyc_status === 1 && (
                <div className="transactionTable">
                    <table border="1" align="center">
                        <thead>
                          <tr><th colSpan="15">Recent Transactions</th></tr>
                          <tr>
                            <td>S/N</td>
                            <td colspan="8">Description</td>
                            <td colSpan="3">Date</td>
                            <td colSpan="3">Amount</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td colspan="8">Topup Account Moniepoint</td>
                            <td colspan="3">21, June, 2024</td>
                            <td colspan="3" align="right">2,000</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td colspan="8">Buy 20G Data</td>
                            <td colspan="3">22, June, 2024</td>
                            <td colspan="3">6,000</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td colspan="8">Buy 20G Data</td>
                            <td colspan="3">22, June, 2024</td>
                            <td colspan="3">6,000</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td colspan="8">Buy 20G Data</td>
                            <td colspan="3">22, June, 2024</td>
                            <td colspan="3">6,000</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td colspan="8">Buy 20G Data</td>
                            <td colspan="3">22, June, 2024</td>
                            <td colspan="3">6,000</td>
                          </tr>
                        </tbody>
                    </table>
                </div>
                )}
            </div>

            <div className="footer">
                <Footer></Footer>
            </div>
        </div>
    );
};

export default Dashboard;
