import React, {useEffect} from 'react';
import { useLocation,Link,useNavigate } from 'react-router-dom';
import MyLogo from '../components/Logo.jsx';
import '../assets/Login.css';
import { getAuthUser } from '../utils/auth';

const SuccessPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (getAuthUser()) {
        navigate('/dashboard'); // Redirect to dashboard if the user is logged in
    }
  }, [navigate]);

  return (
    <>
      <div className="container">
        <MyLogo title="Sign Up Successful"/>
        <div className="succes-page">
            <h2>Hi <strong>{email}</strong>! </h2>
            <p>You have successfully signed up for Rumbun Data App.</p>
        </div>
    
        <div className="success-back">
          <strong>click&nbsp;<Link to="/login" className="success-link">here</Link>&nbsp;to log in.</strong>
        </div>
      </div>
      <div className="footer"><p>powered by: AITS HUB&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;support line: 08037397040, 08136098764</p></div>
    </>
  );

};

export default SuccessPage;
