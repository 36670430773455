import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    FaWallet, FaMobileAlt, FaWifi, FaLightbulb,
    FaTv, FaBars, FaTimes,
    FaUser, FaHistory, FaIdCard,
    FaTachometerAlt  // Icon for Dashboard
} from 'react-icons/fa';
import '../assets/Dashboard.css';
import logo from '../assets/img/rumbundata2.jpg';


const Kyc = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    return (
        <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
            <div className="top-section">
                <div className="logo">
                    <img src={logo} alt="Logo" />
                </div>
                <button className="toggle-button" onClick={toggleSidebar}>
                    {isSidebarOpen ? <FaTimes size={20} /> : <FaBars size={20} />}
                </button>
            </div>
            <ul className="nav-links">
                <li title="Profile">
                    <Link to="/dashboard">
                        <FaTachometerAlt size={24} className="icon" />
                        {isSidebarOpen && <span>Dashboard</span>}
                    </Link>
                </li>
                
                <li title="Profile">
                    <Link to="/update-profile">
                        <FaUser size={24} className="icon" />
                        {isSidebarOpen && <span>Update Profile</span>}
                    </Link>
                </li>
                <li title="Transaction History">
                    <Link to="/transaction-history">
                        <FaHistory size={24} className="icon" />
                        {isSidebarOpen && <span>Transaction History</span>}
                    </Link>
                </li>
                {/* <li title="KYC">
                    <Link to="/kyc">
                        <FaIdCard size={24} className="icon" />
                        {isSidebarOpen && <span>KYC</span>}
                    </Link>
                </li> */}
                {/* <li title="Fund Wallet">
                    <Link to="/fund-wallet">
                        <FaWallet size={24} className="icon" />
                        {isSidebarOpen && <span>Fund Wallet</span>}
                    </Link>
                </li> */}
                <li title="Buy Airtime">
                    <Link to="/buy-airtime">
                        <FaMobileAlt size={24} className="icon" />
                        {isSidebarOpen && <span>Buy Airtime</span>}
                    </Link>
                </li>
                <li title="Buy Data">
                    <Link to="/buy-data">
                        <FaWifi size={24} className="icon" />
                        {isSidebarOpen && <span>Buy Data</span>}
                    </Link>
                </li>
                <li title="Buy Electricity">
                    <Link to="/buy-electricity">
                        <FaLightbulb size={24} className="icon" />
                        {isSidebarOpen && <span>Buy Electricity</span>}
                    </Link>
                </li>
                <li title="Buy Cable Subscription">
                    <Link to="/buy-cable">
                        <FaTv size={24} className="icon" />
                        {isSidebarOpen && <span>Cable TV Subscription</span>}
                    </Link>
                </li>
            </ul>
        </div>
    );
};
export default Kyc;
