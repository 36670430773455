import React, { useState } from 'react';
import '../assets/Dashboard.css';
import '../assets/Kyc.css';
import SideBar from '../components/SideBar.jsx';
import Footer from '../components/Footer.jsx';
import Header from '../components/Header.jsx';
import { getAuthUser } from '../utils/auth.js';


const BuyData = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const user = getAuthUser();

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const handleFormSubmit = async () => {

    };
    return (
        <div className="dashboard-container">
            <SideBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

            <div className="main-content">
                <Header title="Buy Data" />

                {user.data.user.kyc_status === 1 && (
                <div className="page-content">
                    <div className="kycForm">
                        <div>
                            <div className="form-group">
                                {/* <label htmlFor="bvn">Network</label> */}
                                <select  
                                    name="network" 
                                    id="network"
                                >
                                    <option value="">Select Network Type</option>
                                    <option value="MTN">MTN</option>
                                    <option value="Airtel">Airtel</option>
                                    <option value="GLO">GLO</option>
                                    <option value="9Mobile">9Mobile</option>
                                </select>
                                
                            </div>
                            
                            <div className="form-group">
                                {/* <label htmlFor="nin">Data Type</label> */}
                                <select  
                                    name="datatype" 
                                    id="datatype"
                                >
                                    <option value="">Select Data Type</option>
                                </select>
                            </div>
                            <div className="form-group">
                                {/* <label htmlFor="nin">Reciepient Phone Number</label> */}
                                <input 
                                    type="text" 
                                    name="datatype" 
                                    id="datatype"
                                    placeholder="Reciepient Phone Number"
                                />
                            </div>
                            
                            <div className="form-group">
                                <button 
                                    type="submit" 
                                    className={`submit-button ${loading ? 'disabled' : ''}`}
                                    onClick={handleFormSubmit}
                                >
                                {loading ? (
                                        <>
                                        <div className="spinner"></div>
                                        <span>Buying Data...</span>
                                        </>
                                    ) : (
                                        'Buy Data'
                                    )} 
                                    
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                )}
            </div>
            
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
};

export default BuyData;
