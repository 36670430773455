// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const authUser = JSON.parse(localStorage.getItem('authUser'));

    if (!authUser) {
        // Redirect to login page if not authenticated
        return <Navigate to="/login" />;
    }

    // Render the children components if authenticated
    return children;
};

export default ProtectedRoute;
