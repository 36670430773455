import React from 'react';
import logo from '../assets/img/rumbundata.png';

const Logo = ({ title }) => {
    
    return (
        <>
            <div className="logo"><img src={logo} alt="Logo" /></div>
            <div className="header-page">
                <div className="text">{title}</div>
                <div className="underline"></div>
            </div>
        </>
    );
};

export default Logo;
