// export const getAuthUser = () => {
//     const user = JSON.parse(localStorage.getItem('authUser'));
    
//     if (!user || !user.data || !user.data.token) {
//         console.error("User not found or invalid token.");
//         return null;
//     }

//     return user;
// };

export const getAuthUser = () => {
    const user = JSON.parse(localStorage.getItem('authUser'));

    if (!user || !user.data || !user.data.token) {
        console.error("User not found or invalid token.");
        return null;
    }
    return user;
};

export const removeAuthUser = () => {
    localStorage.removeItem('authUser');
};