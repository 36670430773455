import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthUser, removeAuthUser } from '../utils/auth';

const useInactivityLogout = (timeoutMinutes = 30) => {
    const navigate = useNavigate();
    const timeoutRef = useRef(null);

    const handleLogout = () => {
        removeAuthUser(); // Remove user data from localStorage
        navigate('/login'); // Redirect to login page
    };

    const resetTimer = () => {
        // Clear any existing timer
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        // Set a new timer
        timeoutRef.current = setTimeout(() => {
            handleLogout();
        }, timeoutMinutes * 60 * 1000); // Convert minutes to milliseconds
    };

    useEffect(() => {
        const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart']; // User interactions to monitor

        const resetOnActivity = () => {
            resetTimer();
        };

        // Add event listeners to reset timer on user activity
        events.forEach((event) => window.addEventListener(event, resetOnActivity));

        // Set initial timer when component mounts
        resetTimer();

        // Cleanup function to remove event listeners and clear timeout on unmount
        return () => {
            events.forEach((event) => window.removeEventListener(event, resetOnActivity));
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [timeoutMinutes]);

    return null;
};

export default useInactivityLogout;
